import "@datadog/browser-logs/bundle/datadog-logs";
import { datadogLogs } from "@datadog/browser-logs";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { delay } from "@examspark/common";
import { AppLoading } from "@examspark/common-app/preload";

// @ts-expect-error ts(2339)
window.DD_LOGS.init({
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  env: process.env.REACT_APP_USER_BRANCH,
});

datadogLogs.setGlobalContextProperty("app", "student");

const App = React.lazy(async () => {
  // artifically delay the loading of the app to prevent the loading screen
  // from flashing.
  const [, app] = await Promise.all([delay(1000), import("./App")]);
  return app;
});

// Only responsibility is to lazy-load the rest of the application.
ReactDOM.render(
  <Suspense fallback={<AppLoading />}>
    <App />
  </Suspense>,
  document.getElementById("root")
);
